<template>
  <v-container fluid class="pt-2 px-3">
    <v-toolbar class="rounded-lg" color="white" density="compact" elevation="6">
      <v-list-item
          class="px-6" density="compact">
        <template #prepend>
          <v-avatar
              color="surface-light"
              size="32"
          >
            🔧
          </v-avatar>
        </template>

        <template #title>
          Установка и настройка расширения
        </template>
      </v-list-item>
      <v-spacer></v-spacer>

    </v-toolbar>

    <v-row no-gutters>
      <v-col cols="12"
             sm="12"
             md="12"
             lg="12"
             xl="12"
             xxl="12">
        <v-card flat class="rounded-lg mt-2 mr-1" density="compact">
          <v-timeline side="end" class="mr-2">

            <v-timeline-item
                size="small"
                icon="mdi-numeric-1"
                fill-dot
                dot-color="indigo-darken-2"
            >
              <v-card
                  append-icon="mdi-open-in-new"
                  class="mx-auto"
                  href="https://siriuslab.kz/plugins1c/Плагин%201С.zip"
                  prepend-icon="mdi-puzzle"
                  rel="noopener"
                  variant="tonal"
                  color="indigo-darken-3"
                  subtitle="Скачайте расширение 1C"
                  target="_blank"
                  title="Расширение"
              />
            </v-timeline-item>

            <v-timeline-item
                size="small"
                icon="mdi-numeric-2"
                fill-dot
                dot-color="indigo-darken-2"
            >
              <v-card
                  append-icon="mdi-open-in-new"
                  class="mx-auto"
                  href="https://siriuslab.kz/instruction/instruction.html"
                  prepend-icon="mdi-book-open-page-variant"
                  rel="noopener"
                  variant="tonal"
                  color="indigo-darken-2"
                  subtitle="Обязательно после скачивания настройте согласно инструкции"
                  target="_blank"
                  title="Установите"
              />
            </v-timeline-item>
            <v-timeline-item
                size="small"
                icon="mdi-numeric-3"
                fill-dot
                dot-color="indigo-darken-2"
            >
              <v-card
                  class="mx-auto"
                  prepend-icon="mdi-key"
                  color="indigo-darken-2"
                  variant="flat"
                  subtitle="Ваш токен доступа для 1С плагина, скопируйте значение для дальнейшей настройки"
                  target="_blank"
                  title="Токен доступа"
              >
                <v-card-text>
                  <v-text-field
                      append-inner-icon="mdi-content-copy"
                      readonly
                      :value="token"
                      variant="solo"
                      single-line
                      @click:append-inner="copyToClipboard"
                  />
                  <v-snackbar
                      v-model="tokenSnackbarVisible"
                      color="white"
                  >
                    <v-card
                        subtitle="Токен скопирован в буфер обмена"
                        variant="flat"
                    />
                    <template #actions>
                      <v-btn
                          variant="plain"
                          size="small"
                          @click="tokenSnackbarVisible = false"
                      >
                        Закрыть
                      </v-btn>
                    </template>
                  </v-snackbar>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script setup>

</script>

<script>

export default {
  data: () => ({
    token: null,
    tokenSnackbarVisible: false,
  }),

  async created() {
    try {
      const userData = JSON.parse(sessionStorage.getItem('userData'));
      this.token = userData.token
    } catch (error) {
      console.error("Failed to load user context", error);
    }
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.token)
          .then(() => {
            this.tokenSnackbarVisible = true;
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
    },
    clickInstructionButton() {
      // Using querySelector to find the button by the CSS selector
      const button = document.querySelector(
          "#site > table > tbody > tr:nth-child(5) > td > table > tbody > tr > td:nth-child(4) > div > table > tbody > tr:nth-child(1) > td > table > tbody > tr > td:nth-child(3) > button"
      );

      // Check if the button exists and then click it
      if (button) {
        button.click();
      } else {
        console.warn("Button not found!");
      }
    }
  }
}
</script>
<style scoped>
.sticky-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>