<template>
  <v-container fluid class="pt-2 px-3">
    <v-toolbar class="rounded-lg" color="white" density="compact" elevation="6">
      <v-list-item
          class="px-6" density="compact">
        <template #prepend>
          <v-avatar
              color="surface-light"
              size="32"
          >
            🔧
          </v-avatar>
        </template>

        <template #title>
          Поддержка пользователей
        </template>
      </v-list-item>
      <v-spacer></v-spacer>

    </v-toolbar>

    <v-card
        class="mx-auto mt-2"
        width="650"
    >
      <v-card-item>
        <v-card-title class="font-weight-black text-h4">Контакты</v-card-title>

        <v-card-subtitle>
          <span class="me-1 text-h6">Вы можете связаться с нами любым удобным вам способом.</span>
        </v-card-subtitle>
      </v-card-item>

      <v-card-text>
        <v-list>
<!--          <v-list-item
              color="primary"
              key="0"
              value="mail"
              @click="sendEmail"
              class="email-button text-h6"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-email-outline" color="red"></v-icon>
            </template>

            <v-list-item-title class="text-h6 my-2" v-text="'E-mail: siriussolutionslab@gmail.com'">
            </v-list-item-title>
          </v-list-item>-->
          <v-list-item
              color="primary"
              key="0"
              ripple
              value="Siriuslab_bot"
              href="https://t.me/Siriuslab_bot" target="_blank"
              class="email-button text-h6 my-2"
          >
            <template v-slot:prepend>
              <v-icon color="blue">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                     class="bi bi-telegram" viewBox="0 0 16 16">
                  <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
                </svg>
              </v-icon>
            </template>

            <v-list-item-title class="text-h6" v-text="'Telegram: @Siriuslab_bot'">
            </v-list-item-title>
            <v-list-item-subtitle v-text="'Бот технической поддержки пользователей'">

            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item
              color="primary"
              key="1"
              value="mcto1c"
              href="https://t.me/mcto1c" target="_blank"
              class="email-button text-h6 my-2"
          >
            <template v-slot:prepend>
              <v-icon color="blue">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                     class="bi bi-telegram" viewBox="0 0 16 16">
                  <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
                </svg>
              </v-icon>
            </template>

            <v-list-item-title class="text-h6" v-text="'Telegram: @mcto1c'">
            </v-list-item-title>
            <v-list-item-subtitle
                v-text="'Подпишитесь на телеграм-канал для получения новостей об улучшениях и обновлениях плагина 1C'">

            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'TabOne',
  methods: {
    sendEmail() {
      const email = 'siriussolutionslab@gmail.com';
      const subject = 'Обращение в поддержку';
      const body = 'Текст сообщения, опишите как можно подробней';
      window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }
  }
}
</script>

<style scoped>
.sticky-toolbar {
  position: sticky;
  top: 0; /* Чтобы закрепить вверху окна */
  z-index: 1; /* Обеспечивает, что тулбар будет сверху */
}
</style>