<template>
  <v-app>
    <v-system-bar color="white" style="min-height: 32px;">
      <v-tabs
          v-model="activeTab"
          density="compact"
          align-tabs="start"
          color="indigo-darken-2"
      >
        <v-tab :key="0" >
            Настройки обмена
        </v-tab>
        <v-tab :key="1">
          Плагин 1С
        </v-tab>
        <v-tab :key="2">
          Поддержка
        </v-tab>
      </v-tabs>

      <v-spacer></v-spacer>
    </v-system-bar>

    <v-main class="bg-grey-lighten-3 my-2" >
      <v-window v-model="activeTab"
                v-if="getQueryParam('contextKey')"
               >
        <v-window-item :value="0">
          <AppSettingsTab/>
        </v-window-item>
        <v-window-item :value="1">
          <AppOnecTokenSettingsTab/>
        </v-window-item>
        <v-window-item :value="2">
          <AppSupportTab/>
        </v-window-item>
      </v-window>
      <v-empty-state v-else
                     headline="Загружаем данные"
                     title="Обычно это происходит очень быстро"
                     text="Если вы столкнулись с проблемой, напишите нам в @tg:siriuslab.support.com"
                     image="https://vuetifyjs.b-cdn.net/docs/images/logos/v.png"
      ></v-empty-state>

    </v-main>
  </v-app>
</template>

<script>
import AppSettingsTab from "@/components/AppSettingsTab.vue";
import AppOnecTokenSettingsTab from "@/components/AppOnecTokenSettingsTab.vue";
import AppSupportTab from "@/components/AppSupportTab.vue";
import {getQueryParam} from "@/utils/utils";

export default {
  name: 'App',
  methods: {getQueryParam},
  components: {
    AppSettingsTab,
    AppOnecTokenSettingsTab,
    AppSupportTab
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  mounted() {
    (() => {
      let h = -1;
      let win = null;

      const sendExpand = () => {
        if (typeof win != 'undefined' && win) {
          const scrollHeight = document.documentElement.getBoundingClientRect().height;
          if (scrollHeight !== h) {
            h = scrollHeight;
            const sendObject = {
              height: h
            };
            win.postMessage(sendObject, '*');
          }
        }
      };

      window.addEventListener('load', () => {
        win = parent;
        sendExpand();
      });
      setInterval(sendExpand, 250);
    })();
  }
};
</script>

<style>
</style>
